import { Injectable } from '@angular/core';

import { PrivaSegmentService } from '@priva/analytics/segment';
import { PrivaAuthCurrentUserService } from '@priva/auth/current-user';
import { CurrentUser } from '@priva/auth/current-user/lib/shared/current-user.interface';

import { TrackEventType } from './track-events.model';

@Injectable()
export class TrackEventsService {
    user: CurrentUser;

    constructor(
        private authService: PrivaAuthCurrentUserService,
        private segmentService: PrivaSegmentService,
    ) {
        this.authService.user.subscribe((user) => (this.user = user));
    }

    public track(trackEvent: TrackEventType): Promise<PrivaSegmentService> | undefined {
        if (trackEvent.context === undefined) {
            trackEvent.context = {};
        }

        trackEvent.context.userId = this.user.id;
        trackEvent.context.tenantId = this.user.tenantId;

        return this.segmentService.track(trackEvent.type, {
            ...trackEvent.context,
            ...trackEvent.properties,
        });
    }
}
