import { createAction, props } from '@ngrx/store';

import { ErrorMessage } from '@priva/appshell';

import { OperationTimeDto, SiteAsset } from 'app/core/models';

export const getSites = createAction('[Site] Get all sites');
export const getSitesSuccess = createAction(
    '[Site] Get all sites (success)',
    props<{ sites: SiteAsset[] }>(),
);
export const getSitesError = createAction('[Site] Get all sites (error)', props<{ error: ErrorMessage }>());

export const getOperationTime = createAction('[Site] Get operation time', props<{ siteId: string }>());
export const getOperationTimeSuccess = createAction(
    '[Site] Get operation time (success)',
    props<{ operationTime: OperationTimeDto }>(),
);
export const getOperationTimeError = createAction(
    '[Site] Get operation time (error)',
    props<{ error: ErrorMessage }>(),
);
