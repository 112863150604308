import { createFeatureSelector, createSelector } from '@ngrx/store';

import { SiteState } from '../reducers';

export const getSiteState = createFeatureSelector<SiteState>('site');

export const sites = createSelector(getSiteState, (state) => state.sites);
export const isSitesLoaded = createSelector(getSiteState, (state) => state.isSitesLoaded);
export const sitesError = createSelector(getSiteState, (state) => state.sitesError);
export const operationalTime = createSelector(getSiteState, (state) => state.operationTime);
export const isOperationalTimeLoaded = createSelector(getSiteState, (state) => state.isOperationTimeLoaded);
export const operationalTimeLoadedError = createSelector(getSiteState, (state) => state.operationTimeError);
export const getSiteById = (siteId: string) =>
    createSelector(getSiteState, (state) => state.sites.find((site) => site.id === siteId));
export const hasSiteWithManageReports = () =>
    createSelector(getSiteState, (state) =>
        state.sites.some((site) => site.permissions.manageReports === true),
    );
export const sitesWithManageReports = () =>
    createSelector(getSiteState, (state) =>
        state.sites.filter((site) => site.permissions.manageReports === true),
    );
