import { ValidationError } from './errors';
import { isString, isPlainObject, exists } from './helpers';
var stringError = 'is not a string';
var objError = 'is not an object';
var nilError = 'is nil';
// user identity check could hypothetically could be used in the browser event factory, but not 100% sure -- so this is node only for now
export function assertUserIdentity(event) {
  var USER_FIELD_NAME = '.userId/anonymousId/previousId/groupId';
  var getAnyUserId = function (event) {
    var _a, _b, _c;
    return (_c = (_b = (_a = event.userId) !== null && _a !== void 0 ? _a : event.anonymousId) !== null && _b !== void 0 ? _b : event.groupId) !== null && _c !== void 0 ? _c : event.previousId;
  };
  var id = getAnyUserId(event);
  if (!exists(id)) {
    throw new ValidationError(USER_FIELD_NAME, nilError);
  } else if (!isString(id)) {
    throw new ValidationError(USER_FIELD_NAME, stringError);
  }
}
export function assertEventExists(event) {
  if (!exists(event)) {
    throw new ValidationError('Event', nilError);
  }
  if (typeof event !== 'object') {
    throw new ValidationError('Event', objError);
  }
}
export function assertEventType(event) {
  if (!isString(event.type)) {
    throw new ValidationError('.type', stringError);
  }
}
export function assertTrackEventName(event) {
  if (!isString(event.event)) {
    throw new ValidationError('.event', stringError);
  }
}
export function assertTrackEventProperties(event) {
  if (!isPlainObject(event.properties)) {
    throw new ValidationError('.properties', objError);
  }
}
export function assertTraits(event) {
  if (!isPlainObject(event.traits)) {
    throw new ValidationError('.traits', objError);
  }
}
export function assertMessageId(event) {
  if (!isString(event.messageId)) {
    throw new ValidationError('.messageId', stringError);
  }
}
export function validateEvent(event) {
  assertEventExists(event);
  assertEventType(event);
  assertMessageId(event);
  if (event.type === 'track') {
    assertTrackEventName(event);
    assertTrackEventProperties(event);
  }
  if (['group', 'identify'].includes(event.type)) {
    assertTraits(event);
  }
}
