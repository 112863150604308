import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { SiteAsset } from 'app/core/models';

import { SiteActions } from '../actions';
import { SiteState } from '../reducers';
import { SiteSelectors } from '../selectors';

@Injectable({ providedIn: 'root' })
export class SiteFacade {
    private store$: Store<SiteState> = inject(Store<SiteState>);

    readonly sites$ = this.store$.select(SiteSelectors.sites);
    readonly isSitesLoaded$ = this.store$.select(SiteSelectors.isSitesLoaded);
    readonly sitesError$ = this.store$.select(SiteSelectors.sitesError);

    getSites(): void {
        this.store$.dispatch(SiteActions.getSites());
    }

    getSiteById$(siteId: string): Observable<SiteAsset | undefined> {
        return this.store$.select(SiteSelectors.getSiteById(siteId));
    }

    getOperationTime(siteId: string): void {
        this.store$.dispatch(SiteActions.getOperationTime({ siteId }));
    }

    hasSiteWithManageReports(): Observable<boolean> {
        return this.store$.select(SiteSelectors.hasSiteWithManageReports());
    }

    getSitesWithManageReports(): Observable<SiteAsset[]> {
        return this.store$.select(SiteSelectors.sitesWithManageReports());
    }
}
