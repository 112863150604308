import { __spreadArray } from "tslib";
/**
 * @example
 * pick({ 'a': 1, 'b': '2', 'c': 3 }, ['a', 'c'])
 * => { 'a': 1, 'c': 3 }
 */
export function pick(object, keys) {
  return Object.assign.apply(Object, __spreadArray([{}], keys.map(function (key) {
    var _a;
    if (object && Object.prototype.hasOwnProperty.call(object, key)) {
      return _a = {}, _a[key] = object[key], _a;
    }
  }), false));
}
