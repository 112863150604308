export { deepmerge, deepmergeCustom, deepmergeInto, deepmergeIntoCustom } from 'deepmerge-ts';
function validateCSSProperty(property, value, convertUnitlessToPixels = false) {
  value = value?.toString().trim();
  if (convertUnitlessToPixels && value) {
    // Convert unit-less value to pixels
    // e.g. 10 => 10px
    // NB. We only do this for backwards compatibility for number input properties
    if (/^\d+$/.test(value)) {
      value = `${value}px`;
    }
  }
  const supported = CSS.supports(property, value);
  if (!supported && value) {
    console.warn(`Property '${property}' does not support provided value: '${value}'.`);
  }
  return supported ? value : null;
}
function widthAttribute(value) {
  return validateCSSProperty('width', value, true);
}
function maxWidthAttribute(value) {
  return validateCSSProperty('max-width', value);
}
function heightAttribute(value) {
  return validateCSSProperty('height', value, true);
}
function paddingAttribute(value) {
  return validateCSSProperty('padding', value);
}
function colorAttribute(value) {
  return validateCSSProperty('color', value);
}

/**
 * Generated bundle index. Do not edit.
 */

export { colorAttribute, heightAttribute, maxWidthAttribute, paddingAttribute, widthAttribute };
