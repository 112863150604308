import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { CurrentUser } from '@priva/auth/current-user';
import { DynamicComponent } from '@priva/components/dynamic';

import { ApplicationFeatures, ApplicationToggles } from 'app/app-features.enum';
import { AppError } from 'app/app.model';
import { TenantUserDto } from 'app/core/models';

import { AppActions } from '../actions';
import { AppState } from '../app.state';
import { AppSelectors } from '../selectors';

@Injectable({
    providedIn: 'root',
})
export class AppFacade {
    currentUser$: Observable<CurrentUser | null> = this.store$.select(AppSelectors.getCurrentUser);
    tenantUsers$: Observable<TenantUserDto[]> = this.store$.select(AppSelectors.getTenantUsers);
    isTenantUsersLoaded$: Observable<boolean> = this.store$.select(AppSelectors.getIsTenentUsersLoaded);
    throbbers$: Observable<{ [key: string]: string }> = this.store$.select(AppSelectors.getThrobbers);
    activeDialogs$: Observable<DynamicComponent[]> = this.store$.select(AppSelectors.getActiveDialogs);
    activePanel$: Observable<DynamicComponent | undefined> = this.store$.select(AppSelectors.getActivePanel);
    error$: Observable<AppError | undefined> = this.store$.select(AppSelectors.getError);

    constructor(private store$: Store<AppState>) {}

    dispatchAction(action: Action): void {
        this.store$.dispatch(action);
    }

    hasCurrentUserFeature(feature: ApplicationFeatures | ApplicationToggles) {
        return this.store$.select(AppSelectors.hasCurrentUserFeature(feature));
    }

    loadCurrentUser(): void {
        this.store$.dispatch(AppActions.loadCurrentUser());
    }

    loadTenantUserList() {
        this.store$.dispatch(AppActions.loadTenantUserList());
    }

    openDialog(dialog: DynamicComponent): void {
        this.store$.dispatch(AppActions.openDialog({ dialog }));
    }

    closeDialog(): void {
        this.store$.dispatch(AppActions.closeDialog());
    }
}
