import { __awaiter, __generator } from "tslib";
import { invokeCallback } from '../callback';
/* The amount of time in ms to wait before invoking the callback. */
export var getDelay = function (startTimeInEpochMS, timeoutInMS) {
  var elapsedTime = Date.now() - startTimeInEpochMS;
  // increasing the timeout increases the delay by almost the same amount -- this is weird legacy behavior.
  return Math.max((timeoutInMS !== null && timeoutInMS !== void 0 ? timeoutInMS : 300) - elapsedTime, 0);
};
/**
 * Push an event into the dispatch queue and invoke any callbacks.
 *
 * @param event - Segment event to enqueue.
 * @param queue - Queue to dispatch against.
 * @param emitter - This is typically an instance of "Analytics" -- used for metrics / progress information.
 * @param options
 */
export function dispatch(ctx, queue, emitter, options) {
  return __awaiter(this, void 0, void 0, function () {
    var startTime, dispatched;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          emitter.emit('dispatch_start', ctx);
          startTime = Date.now();
          if (!queue.isEmpty()) return [3 /*break*/, 2];
          return [4 /*yield*/, queue.dispatchSingle(ctx)];
        case 1:
          dispatched = _a.sent();
          return [3 /*break*/, 4];
        case 2:
          return [4 /*yield*/, queue.dispatch(ctx)];
        case 3:
          dispatched = _a.sent();
          _a.label = 4;
        case 4:
          if (!(options === null || options === void 0 ? void 0 : options.callback)) return [3 /*break*/, 6];
          return [4 /*yield*/, invokeCallback(dispatched, options.callback, getDelay(startTime, options.timeout))];
        case 5:
          dispatched = _a.sent();
          _a.label = 6;
        case 6:
          if (options === null || options === void 0 ? void 0 : options.debug) {
            dispatched.flush();
          }
          return [2 /*return*/, dispatched];
      }
    });
  });
}
