import { __assign, __awaiter, __extends, __generator, __spreadArray } from "tslib";
import { resolveAliasArguments, resolveArguments, resolvePageArguments, resolveUserArguments } from '../arguments-resolver';
import { isOffline } from '../connection';
import { Context } from '../context';
import { dispatch } from '@segment/analytics-core';
import { Emitter } from '@segment/analytics-generic-utils';
import { EventFactory } from '../events';
import { isDestinationPluginWithAddMiddleware } from '../plugin';
import { EventQueue } from '../queue/event-queue';
import { Group, User } from '../user';
import autoBind from '../../lib/bind-all';
import { PersistedPriorityQueue } from '../../lib/priority-queue/persisted';
import { version } from '../../generated/version';
import { PriorityQueue } from '../../lib/priority-queue';
import { getGlobal } from '../../lib/get-global';
import { MemoryStorage, UniversalStorage, StoreType, applyCookieOptions, initializeStorages, isArrayOfStoreType } from '../storage';
import { setGlobalAnalytics } from '../../lib/global-analytics-helper';
import { popPageContext } from '../buffer';
var deprecationWarning = 'This is being deprecated and will be not be available in future releases of Analytics JS';
// reference any pre-existing "analytics" object so a user can restore the reference
var global = getGlobal();
var _analytics = global === null || global === void 0 ? void 0 : global.analytics;
function createDefaultQueue(name, retryQueue, disablePersistance) {
  if (retryQueue === void 0) {
    retryQueue = false;
  }
  if (disablePersistance === void 0) {
    disablePersistance = false;
  }
  var maxAttempts = retryQueue ? 10 : 1;
  var priorityQueue = disablePersistance ? new PriorityQueue(maxAttempts, []) : new PersistedPriorityQueue(maxAttempts, name);
  return new EventQueue(priorityQueue);
}
/**
 * The public settings that are set on the analytics instance
 */
var AnalyticsInstanceSettings = /** @class */function () {
  function AnalyticsInstanceSettings(settings) {
    var _a;
    /**
     * Auto-track specific timeout setting   for legacy purposes.
     */
    this.timeout = 300;
    this.writeKey = settings.writeKey;
    this.cdnSettings = (_a = settings.cdnSettings) !== null && _a !== void 0 ? _a : {
      integrations: {},
      edgeFunction: {}
    };
  }
  return AnalyticsInstanceSettings;
}();
export { AnalyticsInstanceSettings };
/* analytics-classic stubs */
function _stub() {
  console.warn(deprecationWarning);
}
var Analytics = /** @class */function (_super) {
  __extends(Analytics, _super);
  function Analytics(settings, options, queue, user, group) {
    var _this = this;
    var _a, _b;
    _this = _super.call(this) || this;
    _this._debug = false;
    _this.initialized = false;
    _this.user = function () {
      return _this._user;
    };
    _this.init = _this.initialize.bind(_this);
    _this.log = _stub;
    _this.addIntegrationMiddleware = _stub;
    _this.listeners = _stub;
    _this.addEventListener = _stub;
    _this.removeAllListeners = _stub;
    _this.removeListener = _stub;
    _this.removeEventListener = _stub;
    _this.hasListeners = _stub;
    _this.add = _stub;
    _this.addIntegration = _stub;
    var cookieOptions = options === null || options === void 0 ? void 0 : options.cookie;
    var disablePersistance = (_a = options === null || options === void 0 ? void 0 : options.disableClientPersistence) !== null && _a !== void 0 ? _a : false;
    _this.settings = new AnalyticsInstanceSettings(settings);
    _this.queue = queue !== null && queue !== void 0 ? queue : createDefaultQueue("".concat(settings.writeKey, ":event-queue"), options === null || options === void 0 ? void 0 : options.retryQueue, disablePersistance);
    var storageSetting = options === null || options === void 0 ? void 0 : options.storage;
    _this._universalStorage = _this.createStore(disablePersistance, storageSetting, cookieOptions);
    _this._user = user !== null && user !== void 0 ? user : new User(__assign({
      persist: !disablePersistance,
      storage: options === null || options === void 0 ? void 0 : options.storage
    }, options === null || options === void 0 ? void 0 : options.user), cookieOptions).load();
    _this._group = group !== null && group !== void 0 ? group : new Group(__assign({
      persist: !disablePersistance,
      storage: options === null || options === void 0 ? void 0 : options.storage
    }, options === null || options === void 0 ? void 0 : options.group), cookieOptions).load();
    _this.eventFactory = new EventFactory(_this._user);
    _this.integrations = (_b = options === null || options === void 0 ? void 0 : options.integrations) !== null && _b !== void 0 ? _b : {};
    _this.options = options !== null && options !== void 0 ? options : {};
    autoBind(_this);
    return _this;
  }
  /**
   * Creates the storage system based on the settings received
   * @returns Storage
   */
  Analytics.prototype.createStore = function (disablePersistance, storageSetting, cookieOptions) {
    // DisablePersistance option overrides all, no storage will be used outside of memory even if specified
    if (disablePersistance) {
      return new UniversalStorage([new MemoryStorage()]);
    } else {
      if (storageSetting) {
        if (isArrayOfStoreType(storageSetting)) {
          // We will create the store with the priority for customer settings
          return new UniversalStorage(initializeStorages(applyCookieOptions(storageSetting.stores, cookieOptions)));
        }
      }
    }
    // We default to our multi storage with priority
    return new UniversalStorage(initializeStorages([StoreType.LocalStorage, {
      name: StoreType.Cookie,
      settings: cookieOptions
    }, StoreType.Memory]));
  };
  Object.defineProperty(Analytics.prototype, "storage", {
    get: function () {
      return this._universalStorage;
    },
    enumerable: false,
    configurable: true
  });
  Analytics.prototype.track = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }
    return __awaiter(this, void 0, void 0, function () {
      var pageCtx, _a, name, data, opts, cb, segmentEvent;
      var _this = this;
      return __generator(this, function (_b) {
        pageCtx = popPageContext(args);
        _a = resolveArguments.apply(void 0, args), name = _a[0], data = _a[1], opts = _a[2], cb = _a[3];
        segmentEvent = this.eventFactory.track(name, data, opts, this.integrations, pageCtx);
        return [2 /*return*/, this._dispatch(segmentEvent, cb).then(function (ctx) {
          _this.emit('track', name, ctx.event.properties, ctx.event.options);
          return ctx;
        })];
      });
    });
  };
  Analytics.prototype.page = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }
    return __awaiter(this, void 0, void 0, function () {
      var pageCtx, _a, category, page, properties, options, callback, segmentEvent;
      var _this = this;
      return __generator(this, function (_b) {
        pageCtx = popPageContext(args);
        _a = resolvePageArguments.apply(void 0, args), category = _a[0], page = _a[1], properties = _a[2], options = _a[3], callback = _a[4];
        segmentEvent = this.eventFactory.page(category, page, properties, options, this.integrations, pageCtx);
        return [2 /*return*/, this._dispatch(segmentEvent, callback).then(function (ctx) {
          _this.emit('page', category, page, ctx.event.properties, ctx.event.options);
          return ctx;
        })];
      });
    });
  };
  Analytics.prototype.identify = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }
    return __awaiter(this, void 0, void 0, function () {
      var pageCtx, _a, id, _traits, options, callback, segmentEvent;
      var _this = this;
      return __generator(this, function (_b) {
        pageCtx = popPageContext(args);
        _a = resolveUserArguments(this._user).apply(void 0, args), id = _a[0], _traits = _a[1], options = _a[2], callback = _a[3];
        this._user.identify(id, _traits);
        segmentEvent = this.eventFactory.identify(this._user.id(), this._user.traits(), options, this.integrations, pageCtx);
        return [2 /*return*/, this._dispatch(segmentEvent, callback).then(function (ctx) {
          _this.emit('identify', ctx.event.userId, ctx.event.traits, ctx.event.options);
          return ctx;
        })];
      });
    });
  };
  Analytics.prototype.group = function () {
    var _this = this;
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }
    var pageCtx = popPageContext(args);
    if (args.length === 0) {
      return this._group;
    }
    var _a = resolveUserArguments(this._group).apply(void 0, args),
      id = _a[0],
      _traits = _a[1],
      options = _a[2],
      callback = _a[3];
    this._group.identify(id, _traits);
    var groupId = this._group.id();
    var groupTraits = this._group.traits();
    var segmentEvent = this.eventFactory.group(groupId, groupTraits, options, this.integrations, pageCtx);
    return this._dispatch(segmentEvent, callback).then(function (ctx) {
      _this.emit('group', ctx.event.groupId, ctx.event.traits, ctx.event.options);
      return ctx;
    });
  };
  Analytics.prototype.alias = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }
    return __awaiter(this, void 0, void 0, function () {
      var pageCtx, _a, to, from, options, callback, segmentEvent;
      var _this = this;
      return __generator(this, function (_b) {
        pageCtx = popPageContext(args);
        _a = resolveAliasArguments.apply(void 0, args), to = _a[0], from = _a[1], options = _a[2], callback = _a[3];
        segmentEvent = this.eventFactory.alias(to, from, options, this.integrations, pageCtx);
        return [2 /*return*/, this._dispatch(segmentEvent, callback).then(function (ctx) {
          _this.emit('alias', to, from, ctx.event.options);
          return ctx;
        })];
      });
    });
  };
  Analytics.prototype.screen = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }
    return __awaiter(this, void 0, void 0, function () {
      var pageCtx, _a, category, page, properties, options, callback, segmentEvent;
      var _this = this;
      return __generator(this, function (_b) {
        pageCtx = popPageContext(args);
        _a = resolvePageArguments.apply(void 0, args), category = _a[0], page = _a[1], properties = _a[2], options = _a[3], callback = _a[4];
        segmentEvent = this.eventFactory.screen(category, page, properties, options, this.integrations, pageCtx);
        return [2 /*return*/, this._dispatch(segmentEvent, callback).then(function (ctx) {
          _this.emit('screen', category, page, ctx.event.properties, ctx.event.options);
          return ctx;
        })];
      });
    });
  };
  Analytics.prototype.trackClick = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }
    return __awaiter(this, void 0, void 0, function () {
      var autotrack;
      var _a;
      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            return [4 /*yield*/, import( /* webpackChunkName: "auto-track" */'../auto-track')];
          case 1:
            autotrack = _b.sent();
            return [2 /*return*/, (_a = autotrack.link).call.apply(_a, __spreadArray([this], args, false))];
        }
      });
    });
  };
  Analytics.prototype.trackLink = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }
    return __awaiter(this, void 0, void 0, function () {
      var autotrack;
      var _a;
      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            return [4 /*yield*/, import( /* webpackChunkName: "auto-track" */'../auto-track')];
          case 1:
            autotrack = _b.sent();
            return [2 /*return*/, (_a = autotrack.link).call.apply(_a, __spreadArray([this], args, false))];
        }
      });
    });
  };
  Analytics.prototype.trackSubmit = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }
    return __awaiter(this, void 0, void 0, function () {
      var autotrack;
      var _a;
      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            return [4 /*yield*/, import( /* webpackChunkName: "auto-track" */'../auto-track')];
          case 1:
            autotrack = _b.sent();
            return [2 /*return*/, (_a = autotrack.form).call.apply(_a, __spreadArray([this], args, false))];
        }
      });
    });
  };
  Analytics.prototype.trackForm = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }
    return __awaiter(this, void 0, void 0, function () {
      var autotrack;
      var _a;
      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            return [4 /*yield*/, import( /* webpackChunkName: "auto-track" */'../auto-track')];
          case 1:
            autotrack = _b.sent();
            return [2 /*return*/, (_a = autotrack.form).call.apply(_a, __spreadArray([this], args, false))];
        }
      });
    });
  };
  Analytics.prototype.register = function () {
    var plugins = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      plugins[_i] = arguments[_i];
    }
    return __awaiter(this, void 0, void 0, function () {
      var ctx, registrations;
      var _this = this;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            ctx = Context.system();
            registrations = plugins.map(function (xt) {
              return _this.queue.register(ctx, xt, _this);
            });
            return [4 /*yield*/, Promise.all(registrations)];
          case 1:
            _a.sent();
            return [2 /*return*/, ctx];
        }
      });
    });
  };
  Analytics.prototype.deregister = function () {
    var plugins = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      plugins[_i] = arguments[_i];
    }
    return __awaiter(this, void 0, void 0, function () {
      var ctx, deregistrations;
      var _this = this;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            ctx = Context.system();
            deregistrations = plugins.map(function (pl) {
              var plugin = _this.queue.plugins.find(function (p) {
                return p.name === pl;
              });
              if (plugin) {
                return _this.queue.deregister(ctx, plugin, _this);
              } else {
                ctx.log('warn', "plugin ".concat(pl, " not found"));
              }
            });
            return [4 /*yield*/, Promise.all(deregistrations)];
          case 1:
            _a.sent();
            return [2 /*return*/, ctx];
        }
      });
    });
  };
  Analytics.prototype.debug = function (toggle) {
    // Make sure legacy ajs debug gets turned off if it was enabled before upgrading.
    if (toggle === false && localStorage.getItem('debug')) {
      localStorage.removeItem('debug');
    }
    this._debug = toggle;
    return this;
  };
  Analytics.prototype.reset = function () {
    this._user.reset();
    this._group.reset();
    this.emit('reset');
  };
  Analytics.prototype.timeout = function (timeout) {
    this.settings.timeout = timeout;
  };
  Analytics.prototype._dispatch = function (event, callback) {
    return __awaiter(this, void 0, void 0, function () {
      var ctx;
      return __generator(this, function (_a) {
        ctx = new Context(event);
        if (isOffline() && !this.options.retryQueue) {
          return [2 /*return*/, ctx];
        }
        return [2 /*return*/, dispatch(ctx, this.queue, this, {
          callback: callback,
          debug: this._debug,
          timeout: this.settings.timeout
        })];
      });
    });
  };
  Analytics.prototype.addSourceMiddleware = function (fn) {
    return __awaiter(this, void 0, void 0, function () {
      var _this = this;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4 /*yield*/, this.queue.criticalTasks.run(function () {
              return __awaiter(_this, void 0, void 0, function () {
                var sourceMiddlewarePlugin, integrations, plugin;
                return __generator(this, function (_a) {
                  switch (_a.label) {
                    case 0:
                      return [4 /*yield*/, import( /* webpackChunkName: "middleware" */'../../plugins/middleware')];
                    case 1:
                      sourceMiddlewarePlugin = _a.sent().sourceMiddlewarePlugin;
                      integrations = {};
                      this.queue.plugins.forEach(function (plugin) {
                        if (plugin.type === 'destination') {
                          return integrations[plugin.name] = true;
                        }
                      });
                      plugin = sourceMiddlewarePlugin(fn, integrations);
                      return [4 /*yield*/, this.register(plugin)];
                    case 2:
                      _a.sent();
                      return [2 /*return*/];
                  }
                });
              });
            })];
          case 1:
            _a.sent();
            return [2 /*return*/, this];
        }
      });
    });
  };
  /* TODO: This does not have to return a promise? */
  Analytics.prototype.addDestinationMiddleware = function (integrationName) {
    var middlewares = [];
    for (var _i = 1; _i < arguments.length; _i++) {
      middlewares[_i - 1] = arguments[_i];
    }
    this.queue.plugins.filter(isDestinationPluginWithAddMiddleware).forEach(function (p) {
      if (integrationName === '*' || p.name.toLowerCase() === integrationName.toLowerCase()) {
        p.addMiddleware.apply(p, middlewares);
      }
    });
    return Promise.resolve(this);
  };
  Analytics.prototype.setAnonymousId = function (id) {
    return this._user.anonymousId(id);
  };
  Analytics.prototype.queryString = function (query) {
    return __awaiter(this, void 0, void 0, function () {
      var queryString;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            if (this.options.useQueryString === false) {
              return [2 /*return*/, []];
            }
            return [4 /*yield*/, import( /* webpackChunkName: "queryString" */'../query-string')];
          case 1:
            queryString = _a.sent().queryString;
            return [2 /*return*/, queryString(this, query)];
        }
      });
    });
  };
  /**
   * @deprecated This function does not register a destination plugin.
   *
   * Instantiates a legacy Analytics.js destination.
   *
   * This function does not register the destination as an Analytics.JS plugin,
   * all the it does it to invoke the factory function back.
   */
  Analytics.prototype.use = function (legacyPluginFactory) {
    legacyPluginFactory(this);
    return this;
  };
  Analytics.prototype.ready = function (callback) {
    if (callback === void 0) {
      callback = function (res) {
        return res;
      };
    }
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        return [2 /*return*/, Promise.all(this.queue.plugins.map(function (i) {
          return i.ready ? i.ready() : Promise.resolve();
        })).then(function (res) {
          callback(res);
          return res;
        })];
      });
    });
  };
  // analytics-classic api
  Analytics.prototype.noConflict = function () {
    console.warn(deprecationWarning);
    setGlobalAnalytics(_analytics !== null && _analytics !== void 0 ? _analytics : this);
    return this;
  };
  Analytics.prototype.normalize = function (msg) {
    console.warn(deprecationWarning);
    return this.eventFactory['normalize'](msg);
  };
  Object.defineProperty(Analytics.prototype, "failedInitializations", {
    get: function () {
      console.warn(deprecationWarning);
      return this.queue.failedInitializations;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Analytics.prototype, "VERSION", {
    get: function () {
      return version;
    },
    enumerable: false,
    configurable: true
  });
  /* @deprecated - noop */
  Analytics.prototype.initialize = function (_settings, _options) {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        console.warn(deprecationWarning);
        return [2 /*return*/, Promise.resolve(this)];
      });
    });
  };
  Analytics.prototype.pageview = function (url) {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            console.warn(deprecationWarning);
            return [4 /*yield*/, this.page({
              path: url
            })];
          case 1:
            _a.sent();
            return [2 /*return*/, this];
        }
      });
    });
  };
  Object.defineProperty(Analytics.prototype, "plugins", {
    get: function () {
      var _a;
      console.warn(deprecationWarning);
      // @ts-expect-error
      return (_a = this._plugins) !== null && _a !== void 0 ? _a : {};
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Analytics.prototype, "Integrations", {
    get: function () {
      console.warn(deprecationWarning);
      var integrations = this.queue.plugins.filter(function (plugin) {
        return plugin.type === 'destination';
      }).reduce(function (acc, plugin) {
        var name = "".concat(plugin.name.toLowerCase().replace('.', '').split(' ').join('-'), "Integration");
        // @ts-expect-error
        var integration = window[name];
        if (!integration) {
          return acc;
        }
        var nested = integration.Integration; // hack - Google Analytics function resides in the "Integration" field
        if (nested) {
          acc[plugin.name] = nested;
          return acc;
        }
        acc[plugin.name] = integration;
        return acc;
      }, {});
      return integrations;
    },
    enumerable: false,
    configurable: true
  });
  // snippet function
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Analytics.prototype.push = function (args) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var an = this;
    var method = args.shift();
    if (method) {
      if (!an[method]) return;
    }
    an[method].apply(this, args);
  };
  return Analytics;
}(Emitter);
export { Analytics };
/**
 * @returns a no-op analytics instance that does not create cookies or localstorage, or send any events to segment.
 */
var NullAnalytics = /** @class */function (_super) {
  __extends(NullAnalytics, _super);
  function NullAnalytics() {
    var _this = _super.call(this, {
      writeKey: ''
    }, {
      disableClientPersistence: true
    }) || this;
    _this.initialized = true;
    return _this;
  }
  return NullAnalytics;
}(Analytics);
export { NullAnalytics };
