/**
 * Define the feature toggles used to enable/disable functionality in the application
 * The string values here MUST match the names of the features as used in either
 * the web.config (where they are prefixed with 'priva:features:') or access control.
 */
export enum ApplicationFeatures {
    viewReports = 'priva.analytics-building.view-reports',
    manageReports = 'priva.analytics-building.manage-reports',
}

/**
 * Toggles compatible with the FeatureToggleApi
 */
export enum ApplicationToggles {
    betaFeature = 'priva.analytics-building.beta-feature-toggle',
    betaConsumptionTableFeature = 'priva.analytics-building.beta-toggle-feature-consumption-table',
    betaSmartInsights = 'priva.analytics-building.smart-insights',
}
